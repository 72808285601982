import React, { useContext } from "react";
import { LanguageContext } from "../../context";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";
import Icon from "../icon";
import "../../utils/group-by.js";

const ProjectsList = (props) => {
  const data = useDataQuery();
  const projects = data.projects;
  const { t } = useContext(LanguageContext);
  const groupedProjects = useGroupedProjects(projects);
  const { lang } = props;
  const breakpoints = useBreakpoint();

  return (
    <section className="row projects-list m-b-80">
      <div className="container projects-content">
        {groupedProjects &&
          groupedProjects?.map((item, index) => {
            // Use the unique 'id' field as the key
            const key = item.items[0]?.node?.id;

            const first = index === 0 && !item.group ? "first" : "";
            const second = index === 1 ? "second m-t-330 p-l-r-56 m-b-66 medium-m-r-2" : "";
            const classes = `row ${first} ${second}`;

            if (index === 0) {
              return (
                <div key={key} className={classes}>
                  <div className="col-md-6 m-r-81 m-w-683 medium-m-r-25">
                    <div className="ministry-image background-beig read-more right m-b-42 beig-box">
                      <img
                        src={item.items[0]?.node?.relationships?.field_image?.image_style_uri?._653_432}
                        alt={item.items[0]?.node?.title}
                        className="m-r--24"
                      />
                      <Link to={item.items[0]?.node?.path?.alias} className="r-more">
                        {t("Veure més")}
                        <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 m-w-507">
                    <h2 className="title ministry-title m-b-8">{item.items[0]?.node?.title}</h2>
                    <div className="ministry-desc">
                      <h5 dangerouslySetInnerHTML={{ __html: item.items[0]?.node?.body?.summary }} />
                    </div>
                  </div>
                </div>
              );
            } else {
              if (breakpoints.md) {
                return item?.items?.map(({ node }, ii) => {
                  // Use the unique 'id' field as the key
                  const subKey = node?.id;
                  const first1 = ii === 0 ? "col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63" : "";
                  const second1 = ii === 1 ? "col-md-6 second-second m-t--304 m-w-625 p-l-r-0 " : "";
                  const classes1 = `${first1} ${second1}`;
                  return (
                    <div key={subKey} className={classes}>
                      <div className="col-md-6 m-r-81 m-w-683 medium-m-r-25">
                        <div className="ministry-image background-beig read-more right m-b-42 beig-box">
                          <img
                            src={node?.relationships?.field_image?.image_style_uri?._653_432}
                            alt={node?.title}
                            className="m-r--24"
                          />
                          <Link to={node?.path?.alias} className="r-more">
                            {t("Veure més")}
                            <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6 m-w-507">
                        <h2 className="title ministry-title m-b-8">{node?.title}</h2>
                        <div className="ministry-desc">
                          <h5 dangerouslySetInnerHTML={{ __html: node?.body?.summary }} />
                        </div>
                      </div>
                    </div>
                  );
                });
              } else {
                return (
                  <div key={key} className={classes}>
                    {item?.items?.map(({ node }, ii) => {
                      // Use the unique 'id' field as the key
                      const subKey = node?.id;
                      const first1 = ii === 0 ? "col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63" : "";
                      const second1 = ii === 1 ? "col-md-6 second-second m-t--304 m-w-625 p-l-r-0 " : "";
                      const classes1 = `${first1} ${second1}`;
                      return (
                        <div key={subKey} className={classes1}>
                          <div className="ministry-image background-beig read-more m-b-42 beig-box">
                            <img
                              src={node?.relationships?.field_image?.image_style_uri?._653_432}
                              alt={node?.title}
                              className="m-t--24 m-l-112"
                            />
                            <Link to={node?.path?.alias} className="r-more">
                              {t("Veure més")}
                              <Icon icon="icons-32-x-32-generic-plus" size={16} className="icon" />
                            </Link>
                          </div>
                          <div className="ministry-title-desc">
                            <h2 className="title ministry-title m-b-8">{node?.title}</h2>
                            <div>{/* <MobileOnlyComponent /> will only be displayed if max-width <= 320px  */}</div>
                            <div className="ministry-desc">
                              <h5 dangerouslySetInnerHTML={{ __html: node?.body?.summary }} />
                            </div>
                          </div>
                        </div>
                      );
                    })};
                  </div>
                );
              }
            }
          })}
        <div className="row p-l-r-5 text-center">
          <div className="container">
            <Link
              title={t("Veure tots els projectes")}
              className="miniestries-cta btn btn-outline btn-lg"
              to={`${lang !== "ca" ? "/" + lang : ""}/projectes`}
            >
              {t("Veure tots els projectes")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const useGroupedProjects = (projects) => {
  return projects?.edges?.groupArrayBy((item, index) => index !== 0);
};

const useDataQuery = () => {
  return useStaticQuery(graphql`
    query ProjectsGoelListQuery {
      projects: allNodeProjectsGoel(
        limit: 3,
        filter: {
          promote: { eq: true },
          field_display_pages: {
            elemMatch: {
              drupal_internal__target_id: {
                eq: 3163
              }
            }
          }
        }
      ) {
        edges {
          node {
            path {
              alias
            }
            sticky
            title
            promote
            body {
              summary
            }
            drupal_id
            relationships {
              field_image {
                image_style_uri {
                  large
                  medium
                  thumbnail
                  wide
                  _653_432
                }
              }
            }
            status
          }
        }
      }
    }
  `);
};

export default ProjectsList;
