Array.prototype.groupArrayBy = function(cb){
  const groups = [];
  this.forEach((d, id, arr) => {
      const g = cb(d, id, arr);
      let group = groups.find(_g => _g.group === g);
      if (!group) {
          group = { group: g, items: [] };
          groups.push(group);
      }
      group.items.push(d);
  })
  return groups;
 };
