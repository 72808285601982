import React from 'react';

const Sponsors = (props) => {
  const {title, description, sponsorsList, lang, className} = props
  return (
    <section className={className} >
        <div className="container mb-5 mb-md-6">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
              <h2 className="h2-medium-c organge H2-Tablet-Medium-C--orange">{title}</h2>
              <p className="text-secondary mb-4 mb-md-5">{description}</p>
            </div>
          </div>
        </div>
        <div className="container overflow-hidden sponsors">
          <div className="row gy-5 gy-md-6">
          {sponsorsList && sponsorsList.map(({node}, index) => {
            return (
              <div
                className="col-6 col-md-3 align-self-center text-center"
                key={index}
              >
                <a
                  href={node.field_url?.uri}
                  alt={node.field_url?.title}
                  target="_blank"
                >
                  <img
                    src={node?.relationships?.field_image?.image_style_uri?.sponsors}
                    alt={node?.title}
                  />
                </a>
              </div>
            )
          })}
          </div>
        </div>
     </section>
  )
}

export default Sponsors
