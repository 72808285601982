import React, { useContext } from "react"
import { LanguageContext } from "../context"
import PropTypes from "prop-types"

const NoSearchResults = (props) => {
  const { classes } = props
  const { t } = useContext(LanguageContext)
  return(
    <section
      className={`no-search-results d-flex justify-content-center p-t-122 p-b-100 ${classes}`}
    >
        <h2 className="H2-Medium-C---orange">{t("No s'han trobat resultats")}</h2>
    </section>
  )
}

NoSearchResults.propTypes = {
  classes: PropTypes.string
}

NoSearchResults.defaultProps = {
  classes: ``
}

export default NoSearchResults
