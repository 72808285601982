import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import BlogItem from "./blog-item"
import Utils from "../../utils"
import { navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import PropTypes from "prop-types"
import NoSearchResults from "../../utils/no-search-results"

 const BlogList = (props) => {

   const {
      title,
      blogPosts,
      lang,
      className,
      classContainer,
      classRow,
      buttonUrl,
      buttonTitle,
      includeTags
    } = props


   const { t } = useContext(LanguageContext)
   const breakpoints = useBreakpoint()

   return (
     <section className={className}>
       <div className={classContainer}>
       {title &&
         <div className="row m-b-56">
           <div className="col-md-12">
             <h2 className="h2-medium-c bordeux H2-Tablet-Medium-C--bordeaux">
              {title}
              </h2>
           </div>
         </div>
       }
         <div className="row m-b-64 gutter-0">
             {blogPosts?.map((article, index) => {
               return (
                 <BlogItem
                    key={article?.path?.alias}
                    title={article?.title}
                    description={article?.body?.summary}
                    date={article?.created}
                    path={article?.path?.alias}
                    image={article?.relationships?.field_image?.image_style_uri?._294x192 }
                    className={`${ breakpoints.md ? 'col-md-6' : classRow } ${'item-'+index} blog-item`}
                    includeTags={includeTags}
                    categories={article?.relationships?.field_category}
                 />
               )
             })}

             {(!blogPosts || (blogPosts && blogPosts.length == 0)) &&
               <NoSearchResults />
             }
         </div>
         {buttonUrl &&
           <div className="row p-l-r-5 text-center">
             <div className="container">
               <button
                className="section-cta btn btn-outline btn-lg btn-outline-bordeaux"
                onClick={() => navigate(Utils.getPath(buttonUrl, lang))}
                >{t(buttonTitle)}
              </button>
             </div>
           </div>
         }


       </div>
     </section>
   )
 }

 BlogList.propTypes = {
   className: PropTypes.string,
   classContainer: PropTypes.string,
   classRow: PropTypes.string,
   buttonTitle: PropTypes.string,
   buttonUrl: PropTypes.string,
   includeTags: PropTypes.bool
 }

 BlogList.defaultProps = {
   className: `row- blog-list m-b-92 p-b-80- p-l-r-189`,
   classContainer: `blog-list-content`,
   classRow: 'col-md-4 m-r-90 article-list-item max-width-294',
   buttonTitle: "",
   buttonUrl: "",
   includeTags: false
 }

 export default BlogList
