import React, { useContext } from "react"
import { LanguageContext } from "../../context"
//import Icon from "../icon"
//import image from "../../assets/images/homepage/la-nostra-comunitat/img-1@3x.png"
import TeamItem from "./team-item"
import Utils from "../../utils"
import { navigate } from "gatsby"

 const TeamList = (props) => {

   const { title, teamList, lang } = props

   const { t } = useContext(LanguageContext)
   return (
     <section className="team-list m-b-92 p-b-80 p-l-r-56 bg-green">
       <div className="team-list-content">
         <div className="row p-b-64 p-t-79">
           <div className="col-md-12">
             <h2 className="h2-medium-c white H2-Tablet-Medium-C--white"> {title} </h2>
           </div>
         </div>
         <div className="row m-b-65 gutter-0">
             {teamList?.map((article, index) => {
               return (
                 <TeamItem
                    key={article?.field_email + Math.random()}
                    title={article?.title}
                    email={article?.field_email}
                    role={article?.field_role}
                    type={article?.field_team_type}
                    date={article?.created}
                    image={article?.relationships?.field_image?.image_style_uri?.wide}
                    url="/team"
                    className={`col-md-3 team-list-item max-width-254 m-r-24 ${'team-list-item-' + index} ${teamList.length - 1 === index ? 'last-item' : ''}` }
                 />
               )
             })}
         </div>
         <div className="row p-l-r-5 text-center">
           <div className="container">
             <button
              className="section-cta btn btn-outline btn-lg btn-outline-white"
              onClick={() => navigate(Utils.getPath('/team', lang))}
              >{t("Veure tot l'equip")}
            </button>
           </div>
         </div>
       </div>
     </section>
   )
 }

 export default TeamList
